import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'x-csrftoken';
axios.defaults.withCredentials = true;

export const isProduction = process.env.NEXT_PUBLIC_CURRENT === 'production';
export const isLocal = process.env.NEXT_PUBLIC_CURRENT === 'development';

export const CDNLocal = 'https://d25gtx367q1ou3.cloudfront.net';
export const CDNProd = 'https://cdn.wishket.com';

export const defaultProfileImgSrc = `${
  isLocal ? CDNLocal : CDNProd
}/superstack/static/img/default_avatar.png`;

export const defaultLogoImgSrc = `${
  isLocal ? CDNLocal : CDNProd
}/superstack/static/img/company-logo-default.png`;

export const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API;
export const INTERNAL_API_URL = process.env.NEXT_PUBLIC_INTERNAL_API;

export const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const formDataApi = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const internalApi = axios.create({
  baseURL: INTERNAL_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const internalFormDataApi = axios.create({
  baseURL: INTERNAL_API_URL,
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const fetcher = (url, parameters) => {
  return api
    .get(url, {
      params: parameters,
    })
    .then((result) => result.data);
};
