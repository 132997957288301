import { api, formDataApi } from '@/src/utils/api';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';

export const getChangedForm = (data, formData) => {
  const alwaysIncludedKeys = ['stacks', 'representative_name', 'representative_phone']; // ToDo: 동적으로
  const changedData = Object.entries(formData).filter(([key, val]) => {
    return data?.[key] !== val || alwaysIncludedKeys.includes(key);
  });
  return Object.fromEntries(changedData);
};

export const postFormData = async (formData, endpoint) => {
  if (Object.keys(formData).length) {
    const form = new FormData();
    Object.entries(formData).map(([key, val]) => {
      form.append(key, val);
    });

    const res = await formDataApi.post(endpoint, form);
    return res;
  }
};

export const patchOrPostFormData = async (formData, endpoint, obj = null) => {
  if (Object.keys(formData).length) {
    if (obj) {
      const res = await api.patch(endpoint + `/${obj.id}`, {
        ...formData,
      });
      return res;
    } else {
      const res = await api.post(endpoint, {
        ...formData,
      });
      return res;
    }
  }
};

export const bytesToMB = (size, toFixed = 2) => {
  return (size / (1000 * 1000)).toFixed(toFixed);
};

export const toggleLike = async (jobId, mutateLike) => {
  const url = ApiUrls(null, jobId).find((url) => url.name === 'job_like').url;
  try {
    await api.patch(url);
    await mutateLike();
  } catch (error) {
    if (error?.response?.status === 403) {
      alert('관심 포지션에 추가하려면 로그인이 필요합니다.');
    } else {
      handleServerError(error);
    }
  }
};

export const toggleApplyLike = async (jobId, applyId, mutateData = null) => {
  const url = ApiUrls(null, jobId, null, applyId).find(
    (url) => url.name === 'job_apply_interesting',
  ).url;
  try {
    await api.post(url);
    if (mutateData) {
      await mutateData();
    }
  } catch (error) {
    alert(error?.response?.data?.message || error.message || error);
  }
};

export const handleServerError = (error) => {
  const alertMessage = (data) => {
    if (data?.code === 'blank' || data?.code === 'null') {
      alert('필수값을 모두 입력해주세요.');
    } else if (data?.message) {
      alert(data.message);
    }
  };
  if (error?.response?.data) {
    if (error?.response?.data?.message) {
      alertMessage(error?.response?.data);
    } else {
      // ValidationError of rest_framework.
      const e = error?.response?.data;
      try {
        const [key, errors] = Object.entries(e)[0];
        console.log(key);
        if (Array.isArray(errors)) {
          alertMessage(errors?.[0]);
        } else {
          alertMessage(errors);
        }
      } catch (e) {
        alert('에러가 발생했습니다.');
      }
    }
  } else {
    alertMessage(error);
  }
};

export const checkValidations = (validators) => {
  let isValid = true;
  validators.map((validator) => {
    if (!validator()) {
      isValid = false;
      return false;
    }
  });
  return isValid;
};

export const checkObjectValuesEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.values(obj).every((value) => {
    return !value || value.length === 0;
  });
};

export const normalizeStackName = (name) => {
  return name.trim().toLowerCase().replace(/-/g, '_').replace(/ /g, '_');
};

export const handleClickRouteBackMobile = (router, referer) => {
  if (referer) {
    router.push(referer, referer);
  } else if (router.pathname === '/superstack/jobs/[id]') {
    const landingUrl = GeneralLinks().find((link) => link.name === 'landing')?.url;
    router.push(landingUrl, landingUrl);
  } else {
    router.back();
  }
};

export const toggleSideBar = (mobileSideBarOpen, setMobileSideBarOpen) => {
  setMobileSideBarOpen((current) => !current);
  if (!mobileSideBarOpen && typeof window != 'undefined' && window.document) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto';
  }
};
